import axios, { AxiosRequestConfig } from "axios";
import Vue from "vue";
import { Action } from "@/store/actionType";
import { eventHub } from "@/utils/eventHub";
import JWTStore from "@/store/modules/jwt";

export let Base = process.env.VUE_APP_API;
if (!Base || Base == "/") {
  Base = "";
}

const actionBase = axios.create({
  baseURL: Base + Action.API
});

actionBase.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.getItem("access_token");

actionBase.interceptors.response.use(
  function(response) {
    return response;
  },
  async function(error) {
    if (error.response.status === 401) {
      if (error.response.config.url == `/${Action.RefreshToken}/`) {
        console.log(error, error.response);
        if (
          error.response.data.message === "Token is invalid or expired" ||
          error.response.data.message === "Token 'exp' claim has expired"
        ) {
          JWTStore.logOut();
        }
      }
      if (
        error.response.data &&
        error.response.data.errors &&
        error.response.data.errors.code === "token_not_valid" &&
        error.response.data.errors.messages &&
        error.response.data.errors.messages.length > 0 &&
        error.response.data.errors.messages[0]["token_class"] === "AccessToken"
      ) {
        const refresh_token = localStorage.getItem("refresh_token");
        if (refresh_token) {
          const tok = await JWTStore.getAccessToken();
          return new Promise(resolve => {
            error.response.config.headers.Authorization =
              "Bearer " + localStorage.getItem("access_token");
            resolve(axios(error.response.config));
          });
        } else {
          clearJWT();
        }
      } else if (
        error.response.data &&
        error.response.data.errors &&
        error.response.data.errors.code === "token_not_valid" &&
        (error.response.data.message === "Token is invalid or expired" ||
          error.response.data.message === "Token 'exp' claim has expired")
      ) {
        JWTStore.logOut();
      }
    }

    return Promise.reject(error);
  }
);

const vm = new Vue();

export async function postApi(action: string, param: any, extra?: any): Promise<any> {
  // eventHub.$emit("before-request");
  return await new Promise((resolve, reject) => {
    setJWT();
    if (
      action.includes("forgotpassword") ||
      action.includes("resetpassword") ||
      action.includes("changesuccess")
    ) {
      clearJWT();
    }
    if (!action.includes("?") && action[action.length - 1] != "/") {
      action += "/";
    }

    let promise = actionBase.post(`/${action}`, param, extra);
    promise.then(
      res => {
        eventHub.$emit("after-response");
        resolve(res.data);
      },
      err => {
        eventHub.$emit("after-response");
        if (err.response) {
          if (err.response.status === 404) {
            vm.$notify.error("Not Found");
          } else if (err.response.status === 500) {
            vm.$notify.error("Internal Server Error");
          }
        } else {
          vm.$notify.error("Network Error");
        }
        reject(err);
      }
    );

    // For error with status code 400 Bad Request
    promise.catch(err => {
      eventHub.$emit("after-response");
      if (err.response && err.response.status && err.response.status === 400 && err.response) {
        if (err.response.status === 400 && err.response.data.new_username) {
          for (var i = 0; i < err.response.data.new_username.length; i++) {
            vm.$notify.error(err.response.data.new_username[i]);
          }
        }
        // else if (
        //   err.response.status === 400 &&
        //   err.response.data.refresh[0] === "This field may not be null."
        // ) {
        //   JWTStore.logOut();
        // }
        else if (
          err.response.status === 400 &&
          err.response.data.code === "token_not_valid" &&
          err.response.data.detail === "Invalid Token"
        ) {
          // vm.$snotify.error("Verifying Token", "Error");
        } else if (err.response.status === 400 && err.response.data.non_field_errors) {
          for (var i = 0; i < err.response.data.non_field_errors.length; i++) {
            vm.$notify.error(err.response.data.non_field_errors[i]);
          }
        } else if (err.response.status === 400 && err.response.data.username) {
          for (var i = 0; i < err.response.data.username.length; i++) {
            vm.$notify.error(err.response.data.username[i]);
          }
        } else if (err.response.status === 400 && err.response.data.new_password) {
          for (var i = 0; i < err.response.data.new_password.length; i++) {
            vm.$notify.error(err.response.data.new_password[i]);
          }
        } else if (err.response.status === 400 && err.response.data.current_password) {
          for (var i = 0; i < err.response.data.current_password.length; i++) {
            vm.$notify.error(err.response.data.current_password[i]);
          }
        } else if (err.response.status === 400 && err.response.data.message) {
          vm.$notify.error(err.response.data.message);
        } else if (err.response.status === 400 && err.response.data.Message) {
          vm.$notify.error(err.response.data.Message);
        } else if (err.response.status === 400) {
          vm.$notify.error("Error");
        }
        reject(err);
      }
    });

    // For error with status code 401 Unauthorized
    promise.catch(err => {
      eventHub.$emit("after-response");
      if (err.response && err.response.status === 401) {
        if (err.response.data && err.response.data.detail) {
          vm.$notify.error(err.response.data.detail);
        }
        reject(err);
      }
    });

    // For error with status code 403 Forbidden
    promise.catch(err => {
      eventHub.$emit("after-response");
      if (err.response && err.response.status === 403) {
        if (err.response.data.Message) {
          vm.$notify.error(err.response.data.Message);
        } else if (err.response.data.message) {
          vm.$notify.error(err.response.data.message);
        }
        reject(err);
      }
    });

    // promise.catch(err => {
    //   eventHub.$emit("after-response");
    //   reject(err);
    // });
  });
}

export async function patchApi(action: string, data: any, extra?: any): Promise<any> {
  // eventHub.$emit("before-request");
  return await new Promise((resolve, reject) => {
    setJWT();
    if (
      action.includes("forgotpassword") ||
      action.includes("resetpassword") ||
      action.includes("changesuccess")
    ) {
      clearJWT();
    }

    let promise = actionBase.patch(`/${action}/`, data, extra);
    promise.then(
      res => {
        eventHub.$emit("after-response");
        resolve(res.data);
      },
      err => {
        eventHub.$emit("after-response");
        if (err.response) {
          if (err.response.status === 404) {
            vm.$notify.error("Not Found");
          } else if (err.response.status === 500) {
            vm.$notify.error("Internal Server Error");
          }
        } else {
          vm.$notify.error("Network Error");
        }
        reject(err);
      }
    );

    // For error with status code 400 Bad Request
    promise.catch(err => {
      eventHub.$emit("after-response");
      if (err.response.status === 400 && err.response) {
        if (err.response.status === 400 && err.response.data.new_username) {
          for (var i = 0; i < err.response.data.new_username.length; i++) {
            vm.$notify.error(err.response.data.new_username[i]);
          }
        }
        // else if (
        //   err.response.status === 400 &&
        //   err.response.data.refresh[0] === "This field may not be null."
        // ) {
        //   JWTStore.logOut();
        // }
        else if (
          err.response.status === 400 &&
          err.response.data.code === "token_not_valid" &&
          err.response.data.detail === "Invalid Token"
        ) {
          // vm.$snotify.error("Verifying Token", "Error");
        } else if (err.response.status === 400 && err.response.data.non_field_errors) {
          for (var i = 0; i < err.response.data.non_field_errors.length; i++) {
            vm.$notify.error(err.response.data.non_field_errors[i]);
          }
        } else if (err.response.status === 400 && err.response.data.username) {
          for (var i = 0; i < err.response.data.username.length; i++) {
            vm.$notify.error(err.response.data.username[i]);
          }
        } else if (err.response.status === 400 && err.response.data.new_password) {
          for (var i = 0; i < err.response.data.new_password.length; i++) {
            vm.$notify.error(err.response.data.new_password[i]);
          }
        } else if (err.response.status === 400 && err.response.data.current_password) {
          for (var i = 0; i < err.response.data.current_password.length; i++) {
            vm.$notify.error(err.response.data.current_password[i]);
          }
        } else if (err.response.status === 400 && err.response.data.message) {
          vm.$notify.error(err.response.data.message);
        } else if (err.response.status === 400 && err.response.data.Message) {
          vm.$notify.error(err.response.data.Message);
        } else if (err.response.status === 400) {
          vm.$notify.error("Error");
        }
        reject(err);
      }
    });

    // For error with status code 401 Unauthorized
    promise.catch(err => {
      eventHub.$emit("after-response");
      if (err.response.status === 401) {
        vm.$notify.error(err.response.data.detail);
        reject(err);
      }
    });

    // For error with status code 403 Forbidden
    promise.catch(err => {
      eventHub.$emit("after-response");
      if (err.response.status === 403) {
        if (err.response.data.Message) {
          vm.$notify.error(err.response.data.Message);
        } else if (err.response.data.message) {
          vm.$notify.error(err.response.data.message);
        }
        reject(err);
      }
    });

    // promise.catch(err => {
    //   eventHub.$emit("after-response");
    //   reject(err);
    // });
  });
}

export async function getApi(
  action: string,
  params: string = "",
  config?: AxiosRequestConfig
): Promise<any> {
  return await new Promise((resolve, reject) => {
    setJWT();
    if (action === "crops" || action === "cropcategorynavbar") {
      clearJWT();
    }
    eventHub.$emit("before-request");
    actionBase.get(`/${action}/${params}`, config).then(
      res => {
        eventHub.$emit("after-response");
        resolve(res.data);
      },
      err => {
        eventHub.$emit("after-response");
        if (err.response) {
          vm.$notify.error(err.response.data.Message);
        } else {
          vm.$notify.error("Network Error");
        }
      }
    );
  });
}

export async function getApiWithoutBack(action: string): Promise<any> {
  return await new Promise((resolve, reject) => {
    eventHub.$emit("before-request");
    actionBase.get("/" + action).then(
      res => {
        eventHub.$emit("after-response");
        resolve(res.data);
      },
      err => {
        eventHub.$emit("after-response");
        if (err.response) {
          vm.$notify.error(err.response.data.Message);
        } else {
          vm.$notify.error("Network Error");
        }
      }
    );
  });
}

export async function deleteApi(action: string, param: any): Promise<any> {
  eventHub.$emit("before-request");
  return await new Promise((resolve, reject) => {
    actionBase.delete(`/${action}/${param}/`).then(
      res => {
        eventHub.$emit("after-response");
        resolve(res.data);
      },
      err => {
        eventHub.$emit("after-response");
        if (err.response) {
          if (err.response.data.Message) {
            vm.$notify.error(err.response.data.Message);
          }
          if (err.response.data.message) {
            vm.$notify.error(err.response.data.message);
          }
        } else {
          vm.$notify.error("Network Error");
        }
      }
    );
  });
}

export async function deleteApiWithoutBack(action: string, param: any): Promise<any> {
  eventHub.$emit("before-request");
  return await new Promise((resolve, reject) => {
    actionBase.delete(`/${action}/${param}`).then(
      res => {
        eventHub.$emit("after-response");
        resolve(res.data);
      },
      err => {
        eventHub.$emit("after-response");
        if (err.response) {
          vm.$notify.error(err.response.data.Message);
        } else {
          vm.$notify.error("Network Error");
        }
      }
    );
  });
}

export function setJWT() {
  if (localStorage.getItem("access_token")) {
    actionBase.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("access_token");
  } else {
    clearJWT();
    // console.log("access token: " + localStorage.getItem("access_token"));
  }
}

export function clearJWT() {
  delete actionBase.defaults.headers.common["Authorization"];
}

// Make Axios play nice with Django CSRF
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";
