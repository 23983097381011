import { getApi, postApi, getApiWithoutBack, deleteApi } from "@/store/API/api";
import { Action } from "@/store/actionType";

export default new (class CropAPI {
  public async getCategoryReport(params: string = ""): Promise<any> {
    const url = `${Action.CategoryReport}`;
    const response = await getApi(url, params);
    return response as any;
  }

  public async getAdHocReport(params: string): Promise<any> {
    const url = `${Action.AdHocReport}`;
    const response = await getApi(url, params);
    return response as any;
  }

  public async getTransactionReport(params: string): Promise<any> {
    const url = `${Action.TransactionReport}`;
    const response = await getApi(url, params);
    return response as any;
  }

  public async getSupplyVsDemandReport(query: string): Promise<any> {
    const url = `${Action.SupplyVsDemandReport}`;
    const response = await getApi(url, query, { responseType: "blob" });
    return response as any;
  }
})();
