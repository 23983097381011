import { getApi, postApi, getApiWithoutBack, deleteApi } from "@/store/API/api";
import { Action } from "@/store/actionType";
import { DSADGroup, Allocation } from "../models/dsad";

export default new (class CropAPI {
  public async getAllDSADGroup(params: string = "/"): Promise<any> {
    const url = `${Action.DSADGroup}${params}`;
    const response = await getApiWithoutBack(url);
    return response as any;
  }
  public async getDSADNotice(params: string = ""): Promise<any> {
    const url = `${Action.DSADNotice}`;
    const response = await getApi(url, params);
    return response as any;
  }

  public async getDSADGroupById(id: number): Promise<any> {
    const url = `${Action.DSADGroup}/${id}`;
    const response = await getApi(url);
    return response as any;
  }

  public async saveDSADGroup(param: DSADGroup): Promise<any> {
    const url = `${Action.DSADGroup}`;
    const response = await postApi(url, param);
    return response as any;
  }

  public async deleteDSADGroupById(id: number): Promise<any> {
    const url = `${Action.DSADGroup}`;
    const response = await deleteApi(url, id);
    return response as any;
  }

  public async getAllocation(params: string = "/"): Promise<any> {
    const url = `${Action.Allocate}${params}`;
    const response = await getApiWithoutBack(url);
    return response as any;
  }

  public async saveAllocation(param: Allocation): Promise<any> {
    const url = `${Action.Allocate}`;
    const response = await postApi(url, param);
    return response as any;
  }

  public async getAdminAllocation(query: string = ""): Promise<any> {
    const url = `${Action.AdminAllocation}`;
    const response = await getApi(url, query);
    return response as any;
  }

  public async getDemandForecastReport(query: string = ""): Promise<any> {
    const url = `${Action.DemandForecastReport}`;
    const response = await getApi(url, query);
    return response as any;
  }

  public async getUserAllocation(query: string = ""): Promise<any> {
    const url = `${Action.UserAllocation}`;
    const response = await getApi(url, query);
    return response as any;
  }

  public async downloadexcelreportdistribution(params: {
    id: number;
    batchid: number;
  }): Promise<any> {
    const url = `${Action.ExcelReportDistribution}`;
    let urlquery = "?id=" + String(params.id) + "&batchid=" + String(params.batchid);
    const response = await getApi(url, urlquery);
    return response as any
  }

  public async saveAllocationRootReceipt(params: any): Promise<any> {
    const url = `${Action.AllocationRootReceipt}`;
    const response = await postApi(url, params, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
    return response as any;
  }
})();
